.banner {
  display: flex;
  width: 100%;
}
.banner img {
  max-width: 100%;
}
.wrapper .navbar {
  background-color: #1f497d;
}
.wrapper .navbar .nav-link {
  color: #fff
}

img {
  max-width: 100%;
}

.top_right_content_block { padding-right: 0; margin-bottom: 30px; }
.top_right_content_block .content-block ul { text-align: center;width: 90%; margin: auto; margin-top: 10px; list-style: none; }
.top_right_content_block .content-block ul a { background: #8f7f72; color: #ffffff;     width: 100%;
    display: block;
    margin-bottom: 5px;
    padding: 5px 10px; text-decoration: none; border-radius: 10px;}

.footer {
  background-color: #58595b;
  color: #fff;
  padding: 30px 15px;
  text-align: center;
}

.navbar-custom {
  background-color: #58595b;
  border-radius: 4px;
}
.navbar-custom a {
  color: #fff
}
.page-content {
  padding: 30px 0;
  min-height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.formGeneral {
  max-width: 600px;
  width: 100%;
  margin: 0 auto;
}
.formGeneral > div {
  margin-bottom: 20px;
}
.filename-truncate {
  display: block;
  max-width: 300px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}